import { store } from '@/store/store';
import { personalizaciones_c_371_entrevista } from '@/shared/dtos/personalizaciones/client371/personalizaciones_c_371_entrevista';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'personalizaciones_c_371_entrevistaModule',
    store,
    dynamic: true
})
class personalizaciones_c_371_entrevistaModule extends VuexModule {
    public personalizaciones_c_371_entrevistas: personalizaciones_c_371_entrevista[] = [];
    public personalizaciones_c_371_entrevista: personalizaciones_c_371_entrevista = new personalizaciones_c_371_entrevista();

    @Action({ commit: 'onGetpersonalizaciones_c_371_entrevistas' })
    public async getpersonalizaciones_c_371_entrevistas() {
        return await ssmHttpService.get(API.personalizaciones_c_371_entrevista);
    }

    @Action({ commit: 'onGetpersonalizaciones_c_371_entrevista' })
    public async getpersonalizaciones_c_371_entrevista(id: any) {
        return await ssmHttpService.get(API.personalizaciones_c_371_entrevista + '/' + id);
    }

    @Action({ commit: 'onGetpersonalizaciones_c_371_entrevistas' })
    public async getpersonalizaciones_c_371_entrevistas_paciente(id: number) {
        return await ssmHttpService.get(API.personalizaciones_c_371_entrevista + '/paciente/' + id);
    }


    @Action
    public async guardarpersonalizaciones_c_371_entrevista(personalizaciones_c_371_entrevista: personalizaciones_c_371_entrevista) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.personalizaciones_c_371_entrevista, personalizaciones_c_371_entrevista.toJson());
    }

    @Action
    public async modificarpersonalizaciones_c_371_entrevista(personalizaciones_c_371_entrevista: personalizaciones_c_371_entrevista) {
        return await ssmHttpService.put(API.personalizaciones_c_371_entrevista + '/' + personalizaciones_c_371_entrevista.id, personalizaciones_c_371_entrevista);
    }

    @Action
    public async eliminarpersonalizaciones_c_371_entrevista(personalizaciones_c_371_entrevista: personalizaciones_c_371_entrevista) {
        return await ssmHttpService.delete(API.personalizaciones_c_371_entrevista + '/' + personalizaciones_c_371_entrevista.id, null, false);
    }

    @Mutation
    public onGetpersonalizaciones_c_371_entrevistas(res: personalizaciones_c_371_entrevista[]) {

        this.personalizaciones_c_371_entrevistas = res ? res.map((x) => new personalizaciones_c_371_entrevista(x)) : []
    }

    @Mutation
    public onGetpersonalizaciones_c_371_entrevista(res: personalizaciones_c_371_entrevista) {
        this.personalizaciones_c_371_entrevista = new personalizaciones_c_371_entrevista(res);
    }

}
export default getModule(personalizaciones_c_371_entrevistaModule);